import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const statusSendNotification = {
  isLoading: false,
  isSuccess: false,
  isError: false,
};

/**
 * Send notification, this action is used to send the notification to the user
 * @param {Object} data
 */
export const sendNotification = createAsyncThunk(
  'habeasDataApp/habeasData/sendNotification',
  async (data, { dispatch }) => {
    try {
      const response = await axios.post('notification', data);
      return response.data;
    } catch (error) {
      dispatch(setStatusSendNotification({ isError: true }));
    }
  }
);

const slice = createSlice({
  name: 'habeasDataApp/habeasData',
  initialState: {
    statusSendNotification,
    currentPqr: null,
  },
  reducers: {
    setStatusSendNotification: (state, action) => {
      state.statusSendNotification = { ...statusSendNotification, ...action.payload };
    },
    resetCurrentPqr: (state) => {
      state.currentPqr = null;
    },
  },
  extraReducers: {
    [sendNotification.fulfilled]: (state) => {
      state.statusSendNotification = {
        ...statusSendNotification,
        isSuccess: true,
      };
    },
    [sendNotification.fulfilled]: (state, action) => {
      state.currentPqr = action.payload.pqrNumber;
      state.statusSendNotification = { ...statusSendNotification, isSuccess: true };
    },
  },
});

export const { setStatusSendNotification, resetCurrentPqr } = slice.actions;

export default slice.reducer;
