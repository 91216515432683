import { Controller } from 'react-hook-form';
// ----------------------- MUI -----------------------
import TextField from '@mui/material/TextField';

const TextInputEmail = ({ control, watch, isEmailValid }) => {
  const checkedEmail = watch('checkedEmail');

  if (!checkedEmail) {
    return <div />;
  }

  const validateEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailRegex.test(value)) {
      isEmailValid(true);
      return null;
    }
    isEmailValid(false);
    return { message: 'Ingrese un correo válido' };
  };

  return (
    <Controller
      control={control}
      name="email"
      render={({ field }) => (
        <TextField
          {...field}
          className="mb-24 w-full"
          label="Ingrese el correo electrónico"
          id="email"
          variant="outlined"
          fullWidth
          required
          error={!!validateEmail(field.value)}
          helperText={validateEmail(field.value)?.message}
        />
      )}
    />
  );
};

export default TextInputEmail;
