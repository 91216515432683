import { Controller } from 'react-hook-form';
// ----------------------- MUI -----------------------
import TextField from '@mui/material/TextField';

const TextInputId = ({ control, isInputIdValid }) => {

    

    const validateInputId = (value) => {
        const InputIdRegex = /^[0-9]{6,15}$/g;
        if (InputIdRegex.test(value)) {
            isInputIdValid(true);
            return null;
        }
        isInputIdValid(false);
        return { message: 'Ingrese una identificación válida' };
    };

    return (
        <Controller
            control={control}
            name="documentId"
            render={({ field }) => (
                <TextField
                    {...field}
                    className="mb-24 w-full"
                    label="Identificación"
                    id="InputId"
                    variant="outlined"
                    fullWidth
                    required
                    error={!!validateInputId(field.value)}
                    helperText={validateInputId(field.value)?.message}
                />
            )}
        />
    );
};

export default TextInputId;