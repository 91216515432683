import { createSlice } from '@reduxjs/toolkit';

const baseSlice = createSlice({
  name: 'baseSlice',
  initialState: {
    isLoading: false,
  },
  reducers: {},
});

export default baseSlice.reducer;
