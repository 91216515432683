// ----------------------- Components -----------------------
import HabeasDataHeader from "./HabeasDataHeader";
import HomeButtonReturn from "./components/homebutton/homebutton";
import HabeasDataContent from "./HabeasDataContent";
import FooterBar from "./components/Footer/FooterBar";
// ----------------------- Local files -----------------------
import withReducer from "../../../store/withReducer";
import reducer from "./store";

const HabeasDataApp = () => {
  return (
    <div>
      <div className="md:-space-y-24">
        <div>
          <HabeasDataHeader />
        </div>
        <div className="grid grid-col-1 -mt-36  md:grid-cols-1 xl:grid-cols-3 pt-2 justify-items-center">
          <div>
            <HomeButtonReturn />
          </div>
          <div className="mx-8 sm:mx-28 md:mx-14 -mt-72 xl:mt-4">
            <HabeasDataContent />
          </div>
          <div />
        </div>
        <footer className="text-center -mt-44 md:w-full md:text-left pt-20 ">
          <FooterBar />
        </footer>
      </div>
    </div>
  );
};

export default withReducer("habeasDataApp", reducer)(HabeasDataApp);
