import LoginApp from './LoginApp';

const LoginAppConfig = {
  routes: [
    {
      path: '/login',
      element: <LoginApp />,
    },
  ],
};

export default LoginAppConfig;
