import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// ----------------------- Other libraries -----------------------
import * as XLSX from 'xlsx';
// ----------------------- MUI -----------------------
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
// ----------------------- Firebase -----------------------
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// ----------------------- Local files -----------------------
import withReducer from '../../../store/withReducer';
import reducer from './store';
import { getDataFromDb, updateStatus } from './store/adminSlice';

const AdminApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(({ adminApp }) => adminApp.admin.data);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false); // Local signed-in state.
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setIsAuth(!!user);
      setIsLoading(false);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
    dispatch(getDataFromDb());
  }, [dispatch]);

  if (!isAuth) {
    navigate('/login');
    return <div />;
  }
  if (isLoading) {
    return <div />;
  }

  const attemptUpdateStatus = (id, status) => {
    let newStatus = 'Cerrado';
    if (status === 'Cerrado') {
      newStatus = 'Pendiente';
    }
    dispatch(updateStatus({ id, status: newStatus }));
  };

  const columns = [
    { field: 'pqrNumber', headerName: 'ID', width: 100 },
    { field: 'documentId', headerName: 'Identificación', width: 120 },
    { field: 'fullName', headerName: 'Nombre', width: 250 },
    { field: 'email', headerName: 'Correo', width: 250 },
    { field: 'cellphone', headerName: 'Teléfono', width: 120 },
    { field: 'reason', headerName: 'Motivo Retiro', width: 350 },
    { field: 'createDate', headerName: 'Fecha creación', width: 125 },
    {
      field: 'status',
      headerName: 'Estado',
      width: 150,
      renderCell: (params) => {
        if (params.value === 'Cerrado') {
          return <Chip label={params.value} deleteIcon={<DoDisturbIcon />} color="success" />;
        }

        return <Chip label={params.value} deleteIcon={<PendingActionsIcon />} color="warning" />;
      },
    },
    {
      field: 'action',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={() => {
            attemptUpdateStatus(params.row.id, params.row.status);
          }}
          disabled={params.row.status === 'Cerrado'}
        >
          Cerrar
        </Button>
      ),
    },
  ];

  const onClickExportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, wsAll, 'PQRs');
    XLSX.writeFile(wb, 'PQRs.xlsx');
  };

  return (
    <div className="h-screen">
      <div className="flex flex-row justify-end mt-4 mr-16">
        <Button variant="contained" onClick={() => firebase.auth().signOut()}>
          Cerrar sesión
        </Button>
      </div>

      <div className="flex flex-row justify-center">
        <Typography gutterBottom variant="h6">
          PQRS
        </Typography>
      </div>

      <div style={{ height: '100%', width: '100%' }} className="flex flex-row p-16">
        <div className="flex flex-col w-full h-screen">
          <div className="flex flex-row w-full justify-end mb-4">
            <div className="flex">
              <Button variant="contained" onClick={onClickExportToExcel} color="success">
                Exportar a Excel
              </Button>
            </div>
          </div>
          <div className="flex w-full h-3/6">
            <DataGrid rows={data} columns={columns} pageSize={7} rowsPerPageOptions={[7]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withReducer('adminApp', reducer)(AdminApp);
