// ----------------------- React -----------------------
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// ----------------------- MUI -----------------------
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
// ----------------------- Other libraries -----------------------
import { Controller, useForm } from 'react-hook-form';
import Lottie from 'lottie-react';
// ----------------------- Components -----------------------
import TextInputEmail from './components/TextInputEmail';
import TextInputName from './components/TextInputName';
import TextInputId from './components/TextInputId';
import TextInputSms from './components/TextInputSms';
import TextInputList from './components/TextInputList';
import sendingAnimation from '../../../../resources/anim/sending.json';
import successAnimation from '../../../../resources/anim/success.json';
import errorAnimation from '../../../../resources/anim/error.json';
// ----------------------- Slices -----------------------
import {
  sendNotification,
  setStatusSendNotification,
  resetCurrentPqr,
} from './store/habeasDataSlice';

const defaultValues = {
  checkedEmail: false,
  checkedSMS: false,
  checkedApp: false,
  checkedName: false,
  checkInputId: false,
  email: '',
  phone: '',
  name: '',
  InputId: '',
};

const options = [
  'Envío de mensajes desmedido',
  'No estoy interesado en sus promociones',
  'Ningún beneficio',
  'Contenidos Irrelevantes',
  'Otros',
];

// ----------------------- Custom components -----------------------
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#b8a25a'),
  tonalOffset: '#b8a25a',
  height: 60,
  width: 320,
  borderRadius: 16,
  textTransform: 'none',
  backgroundColor: '#b8a25a',
  '&:hover': {
    backgroundColor: '#b8a25a',
  },
}));

const HabeasDataContent = () => {
  const dispatch = useDispatch();
  const statusSendNotification = useSelector(
    ({ habeasDataApp }) => habeasDataApp.habeasData.statusSendNotification
  );

  const currentPqr = useSelector(({ habeasDataApp }) => habeasDataApp.habeasData.currentPqr);

  const { control, reset, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidSms, setIsValidSms] = React.useState(false);
  const [isValidName, setIsValidName] = React.useState(false);
  const [isValidInputId, setIsValidInputId] = React.useState(false);
  const [value, setValue] = React.useState(options['']);
  const [inputValue, setInputValue] = React.useState('');

  function canBeSubmitted() {
    return (
      value &&
      ((isValidEmail && isValidSms && isValidName && isValidInputId) ||
        (isValidEmail && isValidName && isValidInputId) ||
        (isValidSms && isValidName && isValidInputId))
    );
  }

  React.useEffect(() => {
    if (statusSendNotification.isError) {
      setTimeout(() => {
        reset();
        dispatch(setStatusSendNotification());
      }, 3000);
    }
  }, [dispatch, reset, statusSendNotification]);

  // ----------------------- Methods -----------------------
  const onSubmit = (data) => {
    dispatch(setStatusSendNotification({ isLoading: true }));
    setTimeout(() => {
      dispatch(
        sendNotification({
          clientFullName: data.customerName,
          clientEmail: data.email,
          clientCellphone: data.phone,
          clientDocumentId: data.documentId,
          reason: value,
          unsubscribeApp: data.checkedApp,
        })
      );
    }, 1000);
  };

  const handleIsValidEmail = (value) => {
    setIsValidEmail(value);
  };

  const handleIsValidSms = (value) => {
    setIsValidSms(value);
  };

  const handleIsValidName = (value) => {
    setIsValidName(value);
  };

  const handleIsValidInputId = (value) => {
    setIsValidInputId(value);
  };

  if (statusSendNotification.isLoading) {
    return (
      <div className="flex flex-col md:flex-col-3 items-center my-16 md:my-44">
        <div className="w-5/12">
          <Lottie animationData={sendingAnimation} loop />
        </div>
        <div>
          <Typography gutterBottom variant="h6">
            Estamos guardando la información
          </Typography>
        </div>
      </div>
    );
  }

  if (statusSendNotification.isSuccess) {
    return (
      <div className="flex flex-col md:flex-col-3 items-center my-16 md:my-44">
        <div className="w-2/12">
          <Lottie animationData={successAnimation} loop />
        </div>
        <div className="w-3/3">
          <p className="w-full" align="justify">
            Apreciado cliente, su número de radicado es <b>H{currentPqr}</b> con el cual podrá
            consultar el estado de su solicitud. Recuerde que el retiro de nuestra base de datos de
            promoción de actividades publicitarias podría tardar hasta 24 horas. Gracias por
            contactarse con Dislicores Store.
          </p>
        </div>
        <div className="mt-8">
          <Button
            variant="contained"
            type="submit"
            style={{ backgroundColor: '#b8a25a', color: '#fff' }}
            onClick={() => {
              reset();
              dispatch(resetCurrentPqr());
              dispatch(setStatusSendNotification());
              setValue(options['']);
              setInputValue('');
            }}
          >
            <p className="text-1xl font-semibold text-white">Aceptar</p>
          </Button>
        </div>
      </div>
    );
  }

  if (statusSendNotification.isError) {
    return (
      <div className="flex flex-col md:flex-col-3 items-center my-16 md:my-44">
        <div className="w-5/12">
          <Lottie animationData={errorAnimation} loop />
        </div>
        <div>
          <Typography gutterBottom variant="h6">
            Ha ocurrido un error al guardar la información, por favor intente de nuevo
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col my-36">
      <p className="text-2xl text-center font-bold pb-6 px-36 sm:px-28 md:px-24">
        Cancelación de publicidad.
      </p>
      <div className="">
        <div
          style={{
            height: 650,
            width: 500,
            border: '1px solid #8a8a8a',
            borderRadius: 6,
            backgroundColor: '#eef0f1',
          }}
        >
          <div className="">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <div className="grid justify-items-start ml-8 mt-4">
                  <FormGroup>
                    {/* ----------------------- Input text name ----------------------- */}
                    <div className="-mt-2 -mx-1 flex flex-row">
                      <div className="absolute -mt-2 mx-14 w-72">
                        <TextInputName control={control} isNameValid={handleIsValidName} />
                      </div>
                    </div>
                    {/* ----------------------- Input document ID ----------------------- */}
                    <div className="mt-20 -mx-1 flex flex-row">
                      <div className="absolute mt-2 mx-14 w-72">
                        <TextInputId control={control} isInputIdValid={handleIsValidInputId} />
                      </div>
                    </div>
                    {/* ----------------------- Input text List ----------------------- */}
                    <div className="mt-20 -mx-1 flex flex-row">
                      <div className="absolute -mt-2 mx-14 w-72">
                        <TextInputList
                          value={value}
                          inputValue={inputValue}
                          onChange={(_value) => setValue(_value)}
                          onInputChange={(_value) => setInputValue(_value)}
                          options={options}
                        />
                      </div>
                    </div>
                    {/* ----------------------- Check Box SMS ----------------------- */}
                    <div className=" mt-24 -mx-1 flex flex-row">
                      <Controller
                        control={control}
                        name="checkedSMS"
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{
                                  borderRadius: 3,
                                  color: '#b8a25a',
                                  transform: 'scale(1.9)',
                                }}
                                checked={value}
                                onChange={onChange}
                                disableRipple
                              />
                            }
                            label={
                              <p className="text-black font-semibold text-2xl tracking-wide mx-4">
                                SMS
                              </p>
                            }
                          />
                        )}
                      />
                      <div className="-mt-2 mx-32 absolute w-64">
                        <TextInputSms
                          control={control}
                          formState={formState}
                          watch={watch}
                          isPhoneValid={handleIsValidSms}
                        />
                      </div>
                    </div>
                    {/* ----------------------- Check Box Email ----------------------- */}
                    <div className="mt-8 -mx-1 flex flex-row">
                      <Controller
                        control={control}
                        name="checkedEmail"
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{
                                  borderRadius: 3,
                                  color: '#b8a25a',
                                  transform: 'scale(1.9)',
                                }}
                                checked={value}
                                onChange={onChange}
                                disableRipple
                              />
                            }
                            label={
                              <p className="text-black font-semibold text-2xl tracking-wide mx-4">
                                Email
                              </p>
                            }
                          />
                        )}
                      />
                      <div className="-mt-1 mx-32 absolute w-64">
                        <TextInputEmail
                          control={control}
                          formState={formState}
                          watch={watch}
                          isEmailValid={handleIsValidEmail}
                        />
                      </div>
                    </div>
                    {/* ----------------------- Check Box App ----------------------- */}
                    <div className="mt-8 -mx-1 flex flex-row">
                      <Controller
                        control={control}
                        name="checkedApp"
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{
                                  borderRadius: 3,
                                  color: '#b8a25a',
                                  transform: 'scale(1.9)',
                                }}
                                checked={value}
                                onChange={onChange}
                                disableRipple
                              />
                            }
                            label={
                              <p className="text-black font-semibold text-2xl tracking-wide mx-4">
                                App
                              </p>
                            }
                          />
                        )}
                      />
                    </div>
                    {/* ----------------------- Input text List ----------------------- */}
                    <a
                      href="https://www.dislicores.com/politica-de-privacidad-de-datos"
                      class="underline text-stone-500 font-sans-serif hover:underline text-base my-auto -mx-2 mt-10"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Consulta aquí nuestra política de privacidad de datos
                    </a>

                    {/* ----------------------- Button finish ----------------------- */}
                    <div className="mx-10 mt-8">
                      <ColorButton
                        variant="contained"
                        type="submit"
                        backgroundColor="#f2f0f2"
                        disabled={!canBeSubmitted()}
                      >
                        <p className="text-2xl font-semibold text-white">Enviar</p>
                      </ColorButton>
                    </div>
                  </FormGroup>
                </div>
              </CardContent>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HabeasDataContent;
