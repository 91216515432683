import { useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import * as React from 'react';
import youtubeicon from '../../../../../../resources/youtube-icon.webp';
import Instagramicon from '../../../../../../resources/instagram-icon.webp';
import facebookicon from '../../../../../../resources/facebook-icon.webp';

const FooterBar = () => {
  const statusSendNotification = useSelector(
    ({ habeasDataApp }) => habeasDataApp.habeasData.statusSendNotification
  );
  if (
    statusSendNotification.isLoading ||
    statusSendNotification.isSuccess ||
    statusSendNotification.isError
  ) {
    return <div />;
  }

  return (
    <footer
      className="text-white w-full"
      style={{ backgroundColor: '#141414' }}
    >
      <div className="grid md:grid-cols-6 gap-4 pt-10 pb-16">
        {/* ----------------------- Column_NULL ----------------------- */}
        <div />
        {/* ----------------------- Column_Logo ----------------------- */}
        <div className="flex flex-col my-auto">
          <div>
            <div>
              <Link href="https://www.dislicores.com" color="inherit">
                <img
                  src="https://dislicoresqa.vtexassets.com/assets/vtex/assets-builder/dislicoresqa.storetheme/3.0.16/images/logo/white___c5733e4b5dac3ea2e7e3157380ee06cf.svg"
                  alt="logo"
                  width="200"
                  className="mx-auto"
                />
              </Link>
            </div>
          </div>
          <div className="flex flex-row justify-center mt-4 ">
            <div>
              <Link href="https://www.facebook.com/DislicoresStore" color="inherit">
                <img className="my-2 mr-4" src={facebookicon} alt="logo" width="30" />
              </Link>
            </div>
            <div>
              <Link href="https://www.instagram.com/dislicores" color="inherit">
                <img className="my-2 mr-4" src={Instagramicon} alt="logo" width="30" />
              </Link>
            </div>
            <div>
              <Link href="https://www.youtube.com/channel/UC9vt1aGDvd6hm70sUin9FRQ" color="inherit">
                <img className="my-2" src={youtubeicon} alt="logo" width="30" />
              </Link>
            </div>
          </div>
        </div>
        {/* ----------------------- Column_infoDislicores ----------------------- */}
        <div className="my-auto">
          <div>
            <Link
              href="https://www.dislicores.com/club-dislicores"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Club Dislicores
            </Link>
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com/quienes-somos"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Quienes somos
            </Link>
          </div>
          <div className="my-2 ">
            <Link
              href="https://dislicoresasunegocio.com/"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Dislicores a su negocio
            </Link>
          </div>
        </div>
        {/* ----------------------- Column_Contacto ----------------------- */}
        <div className="my-auto">
          <div
            borderBottom={1}
            className="no-underline hover:font-bold font-sans-serif font-semibold my-1/2 text-xs"
          >
            Atención al cliente
          </div>
          <div className="justify-center md:flex flex-row md:justify-start my-2">
            <WhatsappIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Link
              href="https://api.whatsapp.com/send?phone=+573103895609"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              310 389 5609
            </Link>
          </div>
          <div className="justify-center md:flex flex-row md:justify-start">
            <PhoneIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Link
              href="https://www.dislicores.com/tel:6012415520"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              (601) 241 5520
            </Link>
          </div>
          <div className="justify-center md:flex flex-row my-2 md:justify-start">
            <PhoneIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Link
              href="https://www.dislicores.com/tel:6042042335"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              (604) 204 2335
            </Link>
          </div>
          <div className="justify-center md:flex flex-row md:justify-start my-2">
            <EmailIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Link
              href="mailto:online@dislicores.com"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              online@dislicores.com
            </Link>
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com/preguntas-frecuentes"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Preguntas frecuentes
            </Link>
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com/nuestras-tiendas"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Localizar store
            </Link>
          </div>
        </div>
        {/* ----------------------- Column_TermininosCondiciones ----------------------- */}
        <div>
          <div
            borderBottom={1}
            className="no-underline hover:font-bold font-sans-serif font-semibold my-1/2 text-xs"
          >
            Información legal
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com/politica-de-privacidad-de-datos"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Política de privacidad de datos
            </Link>
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com/responsabilidad-social text-xs"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Responsabilidad social
            </Link>
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com/terminos-condiciones"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Términos y condiciones
            </Link>
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com/politica-de-privacidad-de-datos"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Política de privacidad de datos
            </Link>
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com/politicas-de-uso-de-cookies"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Política de cookies
            </Link>
          </div>
          <div className="my-2">
            <Link
              href="https://www.dislicores.com"
              color="inherit"
              class="no-underline hover:underline font-sans-serif text-xs"
            >
              Términos Club Dislicores
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterBar;
