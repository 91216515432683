import HabeasDataApp from './HabeasDataApp';

const HabeasDataAppConfig = {
  routes: [
    {
      path: '/apps/habeas-data',
      element: <HabeasDataApp />,
    },
  ],
};

export default HabeasDataAppConfig;
