import { Controller } from "react-hook-form";
// ----------------------- MUI -----------------------
import TextField from "@mui/material/TextField";

const TextInputSms = ({ control, isPhoneValid, watch }) => {
  const checkedSMS = watch("checkedSMS");

  if (!checkedSMS) {
    return <div />;
  }

  const validatePhone = (value) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(value)) {
      isPhoneValid(true);
      return null;
    }
    isPhoneValid(false);
    return { message: "Ingrese un número de celular válido" };
  };

  return (
    <Controller
      control={control}
      name="phone"
      render={({ field }) => (
        <TextField
          {...field}
          className="mb-24 w-full"
          label="Ingrese su número de celular"
          id="phone"
          variant="outlined"
          fullWidth
          required
          error={!!validatePhone(field.value)}
          helperText={validatePhone(field.value)?.message}
        />
      )}
    />
  );
};

export default TextInputSms;
