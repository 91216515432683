import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase/compat/app';
import format from 'date-fns/format';

/**
 * Get data from database
 * @param {Object} data
 */
export const getDataFromDb = createAsyncThunk(
  'adminApp/admin/getDataFromDb',
  async (_, { dispatch }) => {
    try {
      const querySnapshot = await firebase.firestore().collection('pqrs').get();
      dispatch(setIsLoading(false));
      return querySnapshot.docs
        .map((doc) => {
          let createDate = doc.data().createDate;
          if (createDate) {
            createDate = format(createDate.toDate(), 'yyyy-MM-dd');
          }
          return {
            ...doc.data(),
            id: doc.id,
            pqrNumber: `H${doc.data().pqrNumber}`,
            consecutive: doc.data().pqrNumber,
            createDate,
          };
        })
        .sort((a, b) => b.consecutive - a.consecutive);
    } catch (error) {
      dispatch(setIsLoading(false));
    }
  }
);

export const updateStatus = createAsyncThunk(
  'adminApp/admin/updateStatus',
  async (data, { dispatch }) => {
    await firebase
      .firestore()
      .collection('pqrs')
      .doc(data.id)
      .update({ ...data });
    dispatch(getDataFromDb());
  }
);

const slice = createSlice({
  name: 'adminApp/admin',
  initialState: {
    isLoading: false,
    data: [],
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: {
    [getDataFromDb.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setIsLoading } = slice.actions;

export default slice.reducer;
