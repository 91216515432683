import AdminApp from './AdminApp';

const AdminAppConfig = {
  routes: [
    {
      path: '/admin',
      element: <AdminApp />,
    },
  ],
};

export default AdminAppConfig;
