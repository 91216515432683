import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#141414",
    },
  },
});

const HabeasDataHeader = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="sticky">
        <Toolbar className="flex justify-center">
          <div>
            <img
              className="my-4"
              src="https://dislicoresqa.vtexassets.com/assets/vtex/assets-builder/dislicoresqa.storetheme/3.0.16/images/logo/white___c5733e4b5dac3ea2e7e3157380ee06cf.svg"
              alt="logo"
              width="150"
            />
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};
export default HabeasDataHeader;
