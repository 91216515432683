import * as React from 'react';
import { useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import HomeIcon from '../../../../../../resources/HomeIcon.webp';

export default function SvgIconsColor() {
  const statusSendNotification = useSelector(
    ({ habeasDataApp }) => habeasDataApp.habeasData.statusSendNotification
  );
  if (
    statusSendNotification.isLoading ||
    statusSendNotification.isSuccess ||
    statusSendNotification.isError
  ) {
    return <div />;
  }

  return (
    <div className="flex flex-row-2 my-36 -mr-16">
      <Link href="https://www.dislicores.com/centro-de-ayuda/" color="inherit">
        <img src={HomeIcon} alt="HomeIcon" width="50" />
      </Link>
      <Link
        href="https://www.dislicores.com/centro-de-ayuda/"
        class="no-underline text-stone-500 font-sans-serif hover:underline text-2xl my-auto mx-2 "
      >
        Regresar
      </Link>
    </div>
  );
}
