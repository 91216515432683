import { Controller } from 'react-hook-form';
// ----------------------- MUI -----------------------
import TextField from '@mui/material/TextField';

const TextInputName = ({ control, isNameValid }) => {

    const validateName = (value) => {
        const NameRegex = /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
        if (NameRegex.test(value)) {
            isNameValid(true);
            return null;
        }
        isNameValid(false);
        return { message: 'Ingrese un nombre válido' };
    };

    return (
        <Controller
            control={control}
            name="customerName"
            render={({ field }) => (
                <TextField
                    {...field}
                    className="mb-24 w-full"
                    label="Ingrese nombre completo"
                    id="Name"
                    variant="outlined"
                    fullWidth
                    required
                    error={!!validateName(field.value)}
                    helperText={validateName(field.value)?.message}
                />
            )}
        />
    );
};

export default TextInputName;