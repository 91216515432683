// ----------------------- React -----------------------
import { Navigate } from 'react-router-dom';
// ----------------------- Components -----------------------
import appsConfig from '../main/apps/appsConfig';
// ----------------------- Local files -----------------------
import Utils from '../../utils';

const routeConfig = [...appsConfig];

const routes = [
  ...Utils.generateRoutesFromConfigs(routeConfig, ['admin', 'user']),
  {
    path: '/',
    element: <Navigate to="apps/habeas-data" />,
  },
  {
    path: '*',
    element: <Navigate to="pages/errors/error-404" />,
  },
];

export default routes;
