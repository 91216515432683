import './App.css';
// ----------------------- React -----------------------
import Provider from 'react-redux/es/components/Provider';
import { useRoutes } from 'react-router-dom';
// ----------------------- Other libraries -----------------------
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// ----------------------- Local files -----------------------
import routes from './configs/routesConfig';
import store from './store';

axios.defaults.baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001/api/v1/'
    : 'https://backend-l3pewfloyq-uc.a.run.app/api/v1/';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const App = () => {
  return <Provider store={store}>{useRoutes(routes)}</Provider>;
};

export default App;
