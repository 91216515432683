import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// ----------------------- Firebase -----------------------
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import dislicoresBlackLogo from './assets/images/dislicores-black.png';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/admin',
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
};

const LoginApp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setIsAuth(!!user);
      setIsLoading(false);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (isAuth) {
    navigate('/admin');
    return <></>;
  }

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="w-full text-center">
      <img className="mx-auto" src={dislicoresBlackLogo} alt="logo" width="150" />
      <p>Por favor inicia sesión:</p>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} className="mt-16" />
    </div>
  );
};

export default LoginApp;
