import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function TextInputList({ value, inputValue, onChange, onInputChange, options }) {
  return (
    <div>
      <br />
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          onInputChange(newInputValue);
        }}
        id="inputList"
        options={options}
        required
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Motivos de retiro" />}
      />
    </div>
  );
}
